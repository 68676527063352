import React from "react";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <main className="w-full max-w-2xl mx-auto">
      <Outlet />
    </main>
  );
}

export default Layout;
