import React, { useState } from "react";
import Logo from "../assets/logo.png";
import CardIcon from "../assets/cardIcon.png";
import ThreeCards from "../assets/3Cards.png";
import TenCards from "../assets/10Cards.png";
import { useNavigate, useSearchParams } from "react-router-dom";
function Home() {
  const [search, setSearch] = useSearchParams();
  const desk = search.get("desk") ? Number(search.get("desk")) : null;
  const [showToast, setToast] = useState(false);
  const navigate = useNavigate();

  const navigateToCards = (number: number) => {
    if (desk === null) {
      setToast(true);
      setTimeout(() => {
        setToast(false);
      }, 2000);
      return;
    }
    navigate(`/cards/${desk}/${number}`);
  };

  return (
    <div className="px-5 text-[#FFFDEE]">
      <div className="w-full flex justify-center mt-7">
        <img className="h-16" src={Logo} alt="" />
      </div>
      <section>
        <h1 className="text-foreground text-3xl mt-5">Choose Deck</h1>
        <div className="w-full flex gap-3 px-3 mt-4 h-28 ">
          <div
            onClick={() => {
              setSearch({ desk: "0" });
            }}
            className={`bg-button flex-1 rounded-2xl shadow-xl flex items-center flex-col text-center p-2 gap-1 ${
              desk === 0 && "bg-buttonActive"
            }`}
          >
            <img src={CardIcon} alt="" />
            The Rider-Waite Tarot
          </div>
          <div
            onClick={() => {
              setSearch({ desk: "1" });
            }}
            className={`bg-button flex-1 rounded-2xl shadow-xl flex items-center flex-col text-center p-2 gap-1 ${
              desk === 1 && "bg-buttonActive"
            }`}
          >
            <img src={CardIcon} alt="" />
            ทำดีสมหวัง
          </div>
        </div>
      </section>
      <section>
        <h1 className="text-foreground text-3xl mt-5">Choose Fortune</h1>
        <div className="w-full flex gap-5 px-3 mt-4 flex-col">
          <div
            onClick={() => {
              navigateToCards(3);
            }}
            className="bg-button flex-1 rounded-2xl shadow-xl flex items-center flex-col p-6 gap-5 active:bg-buttonActive"
          >
            <span className="w-full">3 Cards</span>
            <img src={ThreeCards} alt="" />
          </div>
          <div
            onClick={() => {
              navigateToCards(10);
            }}
            className="bg-button flex-1 rounded-2xl shadow-xl flex items-center flex-col p-6 gap-5 active:bg-buttonActive"
          >
            <span className="w-full">10 Cards</span>
            <img src={TenCards} alt="" />
          </div>
        </div>
      </section>
      {showToast ? (
        <div className="z-30 bg-foreground absolute bottom-11 left-1/2 -translate-x-1/2 rounded-lg p-3 w-8/12 text-center opacity-70 ">
          Please Choose Your Desk
        </div>
      ) : null}
    </div>
  );
}

export default Home;
