import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/logo.png";
import Back from "../assets/back.png";
import CardNumber from "../assets/cardNumber.png";
import Card from "../assets/card.png";

function Cards() {
  const area = useRef<HTMLDivElement>(null);
  const params = useParams() as {
    desk: string;
    number: string;
  };
  const [size, setSize] = useState({ w: 0, h: 0, gap: 0 });
  const [duration, setDuration] = useState("");
  const [data, setData] = useState<number[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const navigate = useNavigate();
  const length = params.desk === "0" ? 78 : 100;

  const shuffleArray = (array: number[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  useEffect(() => {
    const height = area.current?.clientHeight
      ? area.current?.clientHeight / 10
      : 0;
    const gap = area.current?.clientHeight
      ? (area.current?.clientHeight - height) / (length / 2 - 1)
      : 0;
    setSize({ h: height, w: height * 1.5, gap });
    const seq = Array.from({ length: length }, (_, i) => i + 1);
    shuffleArray(seq);
    setData(seq);
    setDuration("transition-all duration-1000");
  }, [length]);

  useEffect(() => {
    if (selected.length === Number(params.number)) {
      let data = "";
      selected.forEach((s, i) => {
        data += `data=${s}${i < selected.length - 1 ? "&" : ""}`;
      });
      navigate(`/fortune?desk=${params.desk}&${data}`);
    }
  }, [selected, navigate, params]);

  return (
    <div className="px-5 flex flex-col h-screen pb-5">
      <div className="w-full flex items-center mt-7 h-16 justify-between ">
        <img
          onClick={() => {
            navigate(-1);
          }}
          className="h-6"
          src={Back}
          alt=""
        />
        <img
          className="h-16 absolute left-1/2 -translate-x-1/2"
          src={Logo}
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="relative">
          <span className="absolute text-2xl text-[#FCE797] left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
            {selected.length}
          </span>
          <img src={CardNumber} alt="" />
        </div>
      </div>
      <div ref={area} className="felx flex-grow relative mx-6">
        {data.map((card, i) => (
          <div
            onClick={(e) => {
              e.currentTarget.style.top = "-1000px";
              setSelected((prev) => [...prev, card]);
            }}
            key={card}
            style={{
              top:
                i <= length / 2 - 1
                  ? size.gap * i
                  : size.gap * (i - length / 2),
              right: i <= length / 2 - 1 ? undefined : 0,
              height: size.h,
              width: size.w,
            }}
            className={`absolute bg-red-900 rounded-xl ${duration}`}
          >
            <img className="w-full h-full shadow-md" src={Card} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
