import React, { useState } from "react";
import Back from "../assets/backTarot.png";

type Props = {
  card: string;
  desk: string;
};
function Card({ card, desk }: Props) {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`${
        desk === "0" ? "aspect-[6.6/12]" : "aspect-[7/11.3]"
      } w-full relative`}
    >
      <div
        onClick={() => {
          setShow((prev) => !prev);
        }}
        className={`w-full h-full card ${show ? null : "hide"}`}
      >
        <img
          className="w-full h-full box-border rounded-md md:rounded-xl object-cover border border-slate-400 front"
          src={`/images/${desk}/${card}.jpg`}
          alt=""
        />
        <img
          className="w-full h-full rounded-md md:rounded-xl overflow-hidden back"
          src={Back}
          alt=""
        />
      </div>
    </div>
  );
}

export default Card;
