import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../assets/logo.png";
import Back from "../assets/back.png";
import Card from "../components/Card";
function Fortune() {
  const [search] = useSearchParams();
  const data = search.getAll("data");
  const desk = search.get("desk");
  const navigate = useNavigate();

  const card = (card: string) => {
    return <Card desk={desk || ""} card={card} />;
  };

  const getThreeCards = () => {
    return (
      <div className="w-full grid grid-cols-3 gap-3 -translate-y-5">
        {data.map((d) => card(d))}
      </div>
    );
  };

  const getTenCards = () => {
    return (
      <div className="w-full grid grid-cols-4 gap-3">
        <div>{card(data[5])}</div>
        <div>{card(data[2])}</div>
        <div>{card(data[9])}</div>
        <div>{card(data[8])}</div>
        <div>{card(data[1])}</div>
        <div>{card(data[0])}</div>
        <div>{card(data[3])}</div>
        <div>{card(data[7])}</div>
        <div className="col-start-2">{card(data[4])}</div>
        <div className="col-start-4">{card(data[6])}</div>
      </div>
    );
  };

  return (
    <div className="px-5 flex flex-col h-screen items-center ">
      <div className="w-full flex items-center mt-7 h-16 justify-between ">
        <img
          onClick={() => {
            navigate(-1);
          }}
          className="h-6"
          src={Back}
          alt=""
        />
        <img
          className="h-16 absolute left-1/2 -translate-x-1/2"
          src={Logo}
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div
        className={`w-full flex flex-1 items-center overflow-scroll ${
          data.length === 3 ? "" : "max-w-lg"
        }`}
      >
        {data.length === 3 ? getThreeCards() : getTenCards()}
      </div>
    </div>
  );
}

export default Fortune;
